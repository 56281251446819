<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="SMS" subtitle="Short Codes" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card short-code-card">
            <div class="card-header">
              <h2 class="card-title col">{{ $tc('generic-str.acc', 2) }}</h2>
              <div class="col-lg-10 search-column">
                <div class="filter-search">
                  <form @submit.prevent="search">
                    <div class="input-group">
                      <input
                        placeholder=""
                        type="text"
                        class="form-control"
                        :v-model="searchQuery"
                        ref="searchInput"
                      />
                      <div class="input-group-prepend">
                        <button
                          type="button"
                          class="btn icon_btn"
                          @click="search"
                        >
                          <span class="material-symbols-outlined">
                            search
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- <span class="material-icons title filter"> swap_vert </span> -->
              </div>
            </div>
          </div>
          <div>
            <TableComponent :fields="fields" :items="shortCodes">
              <template #cell(status)="slotProps">
                <span class="material-symbols-outlined icon-success" v-if="slotProps.item.data.status == 'active'">task_alt</span>
                <span class="material-symbols-outlined icon-failed" v-else>unpublished</span>
              </template>
              <template #cell(alias)="slotProps">
                {{slotProps.item.data.alias}}
              </template>
              <template #cell(actions)="slotProps">
                <router-link :to="`/chat/reports/history/${slotProps.item.id}`"><span
                  class="material-symbols-outlined">search</span></router-link>
              </template>
            </TableComponent>
            <br />
            <pagination :totalPages="pages" v-model="form.page" @change="fetch" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/PaginationComponent.vue';
import TableComponent from '@/components/TableComponent.vue';

export default {
  name: 'SmsShortCodes',
  components: {
    PageHeader,
    Pagination,
    TableComponent,
  },
  data() {
    return {
      fields: [
        'name',
        'alias',
        'status',
      ],
      breadcrumbLinks: {
        1: '/sms/reports/stat',
        2: '/sms/short-codes',
      },

      fetched: false,
      searchQuery: null,
      shortCodes: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
      selectedShortCodes: [],
    };
  },
  computed: {
    selectAllBlacklists: {
      get() {
        if (this.shortCodes) {
          return this.selectedShortCodes.length === this.shortCodes.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.shortCodes.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedShortCodes = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    search() {
      // this.form.page = page;
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      let result = null;
      SmsService.getAccountShortCodes(this.form)
        .then(
          (response) => {
            this.shortCodes = response.data;
            result = this.shortCodes.filter((item) => word.toLowerCase().split(' ').every((v) => item.data.alias.toLowerCase().includes(v)));
            this.shortCodes = result;
            // console.log('Teste: ', this.shortCodes);
            // this.pages = response.last_page;
            this.fetched = true;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getAccountShortCodes(this.form)
        .then(
          (response) => {
            this.shortCodes = response.data;
            // console.log('Teste: ', this.shortCodes);
            this.pages = response.last_page;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
  },
  selectedBlacklistsChange(param) {
    this.selectedShortCodes = param.selectedShortCodes;
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}

/* Rebranding */
.short-code-card .card-header {
  display: flex;
  gap: 10px;
}

.short-code-card .card-title {
  font-weight: 400;
  font-size: 1.25rem;
}

.short-code-table td, .short-code-table th, .short-code-table thead th {
  border-bottom: 1px solid transparent;
}

.short-code-table .control-group.opt-2 {
  width: fit-content;
  margin: unset;
  float: left;
}

.short-code-table tr th:first-child, .short-code-table tr td:first-child {
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (min-width: 768px) {
  .short-code-table tr th:first-child, .short-code-table tr td:first-child {
    text-align: left;
    padding-left: 2rem;
  }
}

@media (max-width: 767.98px) {
  .short-code-table tr td:first-child::before {
    margin-right: auto;
  }
}

.short-code-table td {
  border-top: transparent;
}

.short-code-table button {
  height: auto;
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}

.filter-search {
  max-width: 600px;
  width: inherit;

  input {
    border-right-color: var(--form-input) !important;
  }
}

.filter-search .btn {
  padding: 0;
}

.filter-search ~ .input-group {
  justify-content: flex-end;
}

.icon_btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: var(--form-input);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 15px !important;

  span {
    color: var(--gray-font-color);
  }
}

.darkmode .icon_btn {
  background-color: var(--form-input);
}

.search-column {
  display: flex;
  align-items: center;
  gap: 10px;

  span.filter {
    font-size: 1.5rem;
    transition: 0.5s;
    padding: 9px;
    cursor: pointer;
  }
}

.search-column span:hover:not(.search-column .filter-search span) {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
</style>
